// Loading.sass
$imgSize: 100px

.loading-wrapper
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(252, 252, 252, .75)
  opacity: 0
  z-index: 999
  cursor: default
  visibility: hidden
  transition: all .35s

  &.show
    opacity: 1
    cursor: progress
    visibility: visible

  img
    // display: none
    position: absolute
    // top: calc( 50% - 45px )
    // left: calc( 50% - 45px )
    // width: 90px
    // height: 90px
    top: calc( 50% - 50px )
    left: calc( 50% - 50px )
    width: $imgSize
    height: $imgSize