// Cart.sass
.cart-container
  // padding-top: 6rem
  padding: 0 2rem
  display: flex
  flex-direction: column
  align-items: center
  flex-grow: 1
  width: 100%
  margin-bottom: 2rem

  // position: fixed
  // right: 0
  // top: 0
  // height: 100vh

  .cart-title
    width: 100%
    // padding: 2rem 0
    &.empty 
      h1
        text-align: center

      h2
        font-size: 2.5rem
        text-align: center

    h1
      font-size: 3rem
      margin: 2rem 0

  .cart-items-list
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    .empty-cart-image
      margin-top: 3rem
      margin-bottom: 3rem
      width: 50%

    .empty-cart-text-block
      width: 50%

      p
        margin-bottom: 1rem
        opacity: .8

    .cart-item
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      margin-bottom: 2rem
      width: 100%

      .cart-item-main
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        .cart-item-image
          height: 10rem
          width: 10rem
          margin-right: 2rem

          img
            width: 100%
            height: auto

        .cart-item-body
          display: flex
          flex-direction: column

          .cart-item-title
            font-size: 2.6rem
            font-weight: 500
            margin-bottom: .5rem

          .cart-item-desc
            color: $subtitle-color
            font-size: 1.6rem
            font-weight: 500
            margin-bottom: .5rem
            opacity: .7

      .cart-item-manage
        display: flex
        flex: 0 0 40%
        justify-content: space-between
        align-items: center

        .cart-item-manage-controls
          display: flex
          align-items: center

          .count-control-button
            display: flex
            border-radius: 50rem
            padding: .5rem

            img
              display: flex
              // height: 2rem
              // width: 2rem              
              width: 2.2rem
              height: 2.2rem

            &.button-minus
              // background-color: #e1faf4
              // fill: #009471
              // color: #009471

              // for new icons
              // background-color: #7be3d4
              // fill: #5caa9f
              // color: #5caa9f

              // branded icons
              background-color: $brand-color

            &.button-plus
              // background-color: #e1faf4
              // fill: #009471
              // color: #009471

              // for new icons
              // background-color: #7be3d4
              // fill: #5caa9f
              // color: #5caa9f

              // branded icons
              background-color: #00B475

            &.button-remove
              // background-color: #fae1f4
              // fill: #c21313      
              // color: #c21313

              // for new icons
              // background-color: #fe929f
              // fill: #d86c83
              // color: #d86c83

              // branded icons
              background-color: $brand-color

          .count-control-value
            display: flex
            margin: 0 1.5rem
            font-size: 2.2rem
            // line-height: 1.5

        .cart-item-price span
          font-size: 1.8rem
          font-weight: 500

  .cart-footer    
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%

    &.empty
      justify-content: center

    .cart-footer-control
      display: flex

      .cart-footer-button
        max-width: 20rem
        padding: 1rem 2.5rem
        font-size: 1.8rem