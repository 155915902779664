// common.sass
// $brand-color: #e0523c // pantone color of the 2012 year
$brand-color: #fa6966 // my color from red banner
$brand-color-a: #A32421
$primary-color: #333
$second-color: #3E7FAD
// $second-color: #3E9397
$second-color-a: #145F62
$second-color-b: #71C7CB
$main-background-color: #ffffff
$header-background-color: #ffffff
$title-color: #333
$subtitle-color: #333
$contains-color: #333
$li-not-active-color: #333
$banner-color: #f1f1f1
$button-color: #f1f1f1
$categories-color: #919399
$categories-active-color: #2e2e33
$categories-active-background-color: #e3e4e6
$mobile-cart-toggle-color: #229BD3
$social-icon-circle-color: #565656
$branded-color: rgb(106, 106, 119)

$border-radius-search-input: 24px

%border-rounded-top
  border-top-left-radius: $border-radius-search-input
  -moz-border-radius-topleft: $border-radius-search-input
  -webkit-border-top-left-radius: $border-radius-search-input
  border-top-right-radius: $border-radius-search-input
  -moz-border-radius-topright: $border-radius-search-input
  -webkit-border-top-right-radius: $border-radius-search-input

%border-rounded-bottom
  border-bottom-left-radius: $border-radius-search-input
  -moz-border-radius-bottomleft: $border-radius-search-input
  -webkit-border-bottom-left-radius: $border-radius-search-input
  border-bottom-right-radius: $border-radius-search-input
  -moz-border-radius-bottomright: $border-radius-search-input
  -webkit-border-bottom-right-radius: $border-radius-search-input

$layout-breakpoint-small: 320px
$layout-breakpoint-tablet: 768px
$layout-breakpoint-laptop: 1024px
$layout-breakpoint-mdpi: 1280px
$layout-breakpoint-hdpi: 1440px

::-webkit-scrollbar
  width: 0px
  background: transparent

html::scrollbar
  width: 0px
  background: transparent

body
  background-color: $main-background-color

html
  font-size: 10px
  font-family: 'Avenir Next', sans-serif
  scroll-behavior: smooth

html::-webkit-scrollbar
  display: none

h1
  color: $primary-color
  font-size: 5rem
  font-weight: 900

a
  font-weight: 600
  // color: #5A80E8
  color: $second-color
  &:hover, &:focus, &:active
    opacity: .9

.main-container
  display: flex
  flex-direction: column
  width: 100%
  min-height: 100vh

.overflow-hidden
  overflow: hidden

// ===================================
// ============ Margins ==============
// ===================================

// left
.ml1
  margin-left: 1rem

.ml2
  margin-left: 2rem

.ml3
  margin-left: 3rem

// right
.mr0
  margin-right: 0 !important

.mr1
  margin-right: 1rem

.mr2
  margin-right: 2rem

.mr3
  margin-right: 3rem

// top
.mt0
  margin-top: 0 !important

.mt1
  margin-top: 1rem

.mt2
  margin-top: 2rem

// bottom
.mb0
  margin-bottom: 0 !important

.mb1
  margin-bottom: 1rem

.mb2
  margin-bottom: 2rem

.mb3
  margin-bottom: 3rem

// ===================================
// ============ padding ==============
// ===================================

// right
.pr0
  padding-right: 0 !important

// top
.pt0
  padding-top: 0 !important

.pt1
  padding-top: 1rem

.pt2
  padding-top: 2rem

// bottom
.pb0
  padding-bottom: 0 !important

.pb1
  padding-bottom: 1rem

.pb2
  padding-bottom: 2rem

// ===================================
// ======= Common Components =========
// ===================================

.jc-sb
  justify-content: space-between