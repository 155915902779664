// OrderBody.sass
.order-container
  // padding-top: 6rem
  margin-bottom: 6rem
  display: flex
  flex-direction: column
  // align-items: center
  align-items: flex-start
  // align-items: flex-start
  // min-height: 100vh
  width: 100%

  .order-title
    width: 100%
    padding: 0 2rem

    h1
      font-size: 3rem
      margin: 2rem 0

  .order-body-wrapper
    width: 100%
    padding: 0 2rem
    // display: flex
    // flex: 0 0 70%

    .order-body
      min-width: 30rem

      .order-contact-form
        margin-bottom: 3rem

        .order-contact-form-content
          flex-direction: column

          .main-input-wrapper:first-child
              margin-bottom: 2rem

      .order-type
        margin: 2rem 0

        ul
          display: inline-flex
          margin-right: 1rem

          li
            color: $categories-color
            display: inline-block
            vertical-align: middle
            font-size: 2rem
            cursor: pointer
            font-weight: 500
            background-color: transparent
            border-radius: 99rem
            padding: 1rem 2rem
            margin-right: 1rem

            &.active
              color: $categories-active-color
              background-color: $categories-active-background-color
              cursor: default
              transition-duration: 200ms

          // li
          //   color: $categories-active-color
          //   opacity: .6
          //   margin-right: 1rem
          //   cursor: pointer
          //   transition: opacity 0.15s ease-in-out

          //   &.active
          //     opacity: 1
          //     cursor: default

          li:last-child
            margin-right: 0px

        .discount-description
          display: inline-flex
          color: $categories-color
          font-size: 1.6rem
          padding-top: 1rem

      .order-delivery-block
        .city-and-street-line
          display: flex
          flex-direction: column
          margin-bottom: 2rem
          .city-form
            margin-bottom: 2rem
          // .street-form

        .address-lines
          flex-direction: column
          .address-line-one, .address-line-two
            display: flex
            width: 100%

          .address-line-one
            margin-bottom: 2rem
            .apartment-form, .entrance-form
              width: calc( 50% - 5px )
            .apartment-form
              margin-right: 1rem
            // .house-form
            //   margin-right: 1rem

          .address-line-two
            // .entrance-form
            //   margin-right: 1rem
            .floor-form
              margin-right: 1rem
            // .doorCode-form

    .row
      width: 100%
      position: relative
      display: flex

      h2
        font-size: 2rem
        // font-weight: 500
        font-weight: 600
        // margin: 1rem 0
        margin: 2rem 0

      p
        line-height: 1.25
        font-size: 1.8rem
        font-weight: 500

        a
          font-weight: 600

      // .order-time
      //   width: 100%
      .order-time-dropdowns
        justify-content: space-between

      .payment-type
        // width: 100%

        .cashback-option
          display: flex
          flex-direction: row
          justify-content: space-between
          align-items: center

        p
          // opacity: .6
          // margin-bottom: .5rem
          margin-right: 1rem

      .order-button-complete
        max-width: 20rem
        padding: 1rem 2.5rem
        font-size: 1.8rem     

    .order-notifications
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start

      ul
        // desktop
        display: flex
        position: relative
        width: 100%
        flex-direction: column
        flex-wrap: wrap

      li, p
        // width: 100%
        position: relative
        // width: 50%
        width: 100%
        margin-bottom: 1rem

      p
        line-height: 1.4

    .order-errors
      visibility: hidden
      opacity: 0
      transition: opacity .35s

      &.show
        visibility: visible
        opacity: 1

      ul
        margin-top: 2rem

        li
          display: flex
          position: relative
          margin-top: 1rem
          min-height: 30px
          flex-direction: row
          align-items: center

          &:first-child
            margin-top: 0px

          i
            display: flex
            border-radius: 50rem
            padding: 0.5rem
            background-color: #fe929f

            img
              display: flex
              height: 2rem
              width: 2rem

          span
            font-size: 1.8rem
            font-weight: 500
            padding-left: 1rem

// @media screen and (max-width: 520px)
//   .order-container
//     .order-body-wrapper
//       .order-body
//         .order-contact-form
//           margin-bottom: 3rem

//           .order-contact-form-content
//             flex-direction: column

//             .main-input-wrapper:first-child
//               margin-bottom: 2rem

@media screen and (min-width: 520px)
  .order-container
    .order-body-wrapper
      .order-body
        .order-contact-form
          .order-contact-form-content
            flex-direction: row

            .main-input-wrapper:first-child
              max-width: 25rem
              margin-bottom: 0rem
              margin-right: 3rem
        // .order-notifications 
        //   ul
        //     flex-direction: row
        //     li
        //       width: 50%

@media screen and (min-width: $layout-breakpoint-tablet)
  .order-container
    .order-body-wrapper
      .order-body
        .order-notifications 
          ul
            flex-direction: row
            li
              width: 50%
        .order-delivery-block
          .city-and-street-line
            flex-direction: row
            .city-form
              // width: 25rem
              // min-width: 25rem
              width: 26rem
              min-width: 26rem
              margin-right: 3rem
              margin-bottom: 0
            .street-form
              width: 36rem
              min-width: 36rem

          .address-lines
            flex-direction: row

            .address-line-one
              flex-direction: row
              justify-content: flex-start
              margin-right: 3rem
              margin-bottom: 0
              width: auto
              .house-form
                min-width: 10rem
                width: 10rem
                margin-right: 1rem
              .apartment-form
                width: 13rem
                min-width: 13rem
              .entrance-form
                width: 12rem
                min-width: 12rem
                // margin-right: 1rem

            .address-line-two
              flex-direction: row
              justify-content: flex-start
              .floor-form
                width: 11rem
                min-width: 11rem
                margin-right: 1rem
              .doorCode-form
                width: 24rem
                min-width: 24rem

// @media screen and (max-width: $layout-breakpoint-tablet)
//   .order-container
//     .order-body-wrapper
//       .order-body
//         .order-notifications
//           .delivery-block