// DeliveryZone.sass
.map-component-container
  padding: 0
  margin-bottom: 3.2rem

  &.rounded-map
    .map-container
      .mapboxgl-canvas
        border-radius: 22px
        // overflow: hidden

  h2
    width: 100%
    padding: 1.6rem
    font-size: 3rem
    font-weight: 700
    &.title-in-home-page
      font-size: 5rem
      font-weight: 700
      color: #e8e8e8
      line-height: 1

  .map-container
    width: 100%
    height: 500px
    .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right
      display: none

    .mapboxgl-ctrl-top-right
      .mapboxgl-ctrl-group
        border-radius: 0px
        background: transparent
        // background: #fff

        &:not(:empty)
          // box-shadow: 0 0 8px 8px rgba(0,0,0,.04)
          box-shadow: none

      button
        background-color: #fff
        width: 40px
        height: 40px
        border-radius: 22px
        box-shadow: 0 0 8px 2px rgba(0,0,0,.04)

      .mapboxgl-ctrl
        margin: 20px 20px 0 0

        .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out
          margin-bottom: 1rem

// old version 
// .map-component-container
//   padding: 0
//   margin-bottom: 3.2rem

//   h2
//     width: 100%
//     padding: 1.6rem
//     font-size: 3rem
//     font-weight: 700

//   .leaflet-container-wrapper
//     position: relative

//     .geolocation
//       display: flex
//       justify-content: center
//       align-items: center
//       position: absolute
//       top: 20px
//       left: 20px
//       width: 40px
//       height: 40px
//       z-index: 402
//       border-radius: 99rem
//       background-color: #fff

//       &:hover
//         background-color: #f4f4f4

//       .geolocation-icon
//         width: 20px
//         height: 20px

//     .leaflet-container
//       display: flex
//       width: 100%
//       height: 500px
//       // border-radius: 22px
//       // overflow: hidden
//       font: inherit
//       z-index: 401
      
//       // Delivery Zone Polygon on map
//       .delivery-zone-polygon
//         &:hover
//           opacity: 0.8

//       // remove close button in popup
//       .leaflet-popup-close-button
//         display: none

//       // delivery zone description redisgn
//       .leaflet-tooltip-bottom
//         padding: 5px 15px
//         border-radius: 22px
//         font-size: 16px
//         margin-top: 0px

//       // delivery zone description remove triangle
//       .leaflet-tooltip-bottom:before
//         display: none

//       .leaflet-control-container

//         // move zoom buttons from left to right
//         .leaflet-left
//           // right: 0
//           // left: unset
//           display: none

//           // .leaflet-control-zoom
//           //   margin-right: 20px
//           //   margin-top: 20px
//           //   margin-bottom: 0px
//           //   margin-left: 0px

//         .leaflet-right
//           .leaflet-control-zoom
//             margin-right: 20px
//             margin-top: 20px
//             margin-bottom: 0px
//             margin-left: 0px    

//       .leaflet-tile-container
//         // filter: grayscale(1)
//         // -webkit-filter: grayscale(1)
//         // content: ""
//         // position: absolute
//         // top: 0
//         // right: 0
//         // width: 0
//         // height: 0
//         // background: #0f0248
//         // opacity: .5
//         img
//           // -webkit-filter: grayscale(1) contrast(100%)

//       // remove copyright "leaflet"and others
//       .leaflet-control-attribution
//         display: none

//     // Привожу карту в порядок
//     .leaflet-touch .leaflet-bar 
//       border: 0px

//     .leaflet-touch .leaflet-bar a:first-child 
//       border-radius: 99rem
//       margin-bottom: 1rem

//     .leaflet-touch .leaflet-bar a:last-child
//       border-radius: 99rem

//     // large zoom buttons
//     .leaflet-touch .leaflet-bar a 
//       width: 40px
//       height: 40px
//       line-height: 35px

//   &.rounded-map
//     .leaflet-container-wrapper
//       .leaflet-container
//         border-radius: 22px
//         overflow: hidden