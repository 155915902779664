// MobileProduct.sass
.mobile-product-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  position: fixed
  right: 0
  top: 0
  // height: 100vh
  height: 100%
  width: 100%
  background-color: $main-background-color
  z-index: 9998
  transform: translateX(+100%)
  transition: 0.3s
  opacity: 0
  padding-top: 1.6rem
  padding-left: 1.6rem
  padding-right: 1.6rem

  &.active
    transform: translateX(0)
    opacity: 1     

  .mobile-product-top
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    width: 100%

  .product-image
    display: flex
    height: 34rem
    width: 34rem
    margin-bottom: 2rem
    padding: 1rem
    // временно добавляю для фоток пицц из додо пиццы,
    // из-за того, что у они они смещены влево
    margin-left: 2rem

    img 
      width: 100%
      height: auto

  .product-title
    display: flex
    width: 100%
    margin-bottom: 1rem

    h1
      color: $title-color
      font-size: 2rem

  .product-subtitle
    display: flex
    width: 100%    
    color: $subtitle-color
    flex-direction: row
    align-items: baseline
    justify-content: space-between
    margin-bottom: 1rem
    opacity: .8

    p
      color: $title-color
      display: flex
      font-size: 1.5rem

    .persons-qty:after 
      display: block
      content: ' '
      background-image: url('../assets/icons/user-alt.svg')
      background-size: 1.5rem 1.5rem
      background-repeat: no-repeat
      height: 1.5rem
      width: 1.5rem
      margin-left: .5rem

  .product-contains
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: 100%
    margin-bottom: 1.5rem

    span
      color: $contains-color
      margin-right: .5rem
      margin-bottom: .5rem

  .product-sizes
    display: flex
    margin-bottom: 1.6rem

    ul
      display: flex
      justify-content: space-around
      list-style: none

      li
        color: $subtitle-color
        // font-size: 1.3rem
        font-size: 1.6rem
        border-radius: 12px
        padding: .75rem
        margin-right: .5rem

      .active
        color: $primary-color
        background-color: $button-color  

  .mobile-product-bottom
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-around
    width: 100%
    margin-bottom: 3rem

  .mobile-product-cost
    color: $title-color
    font-size: 2rem

  .mobile-product-add-to-cart
    // margin-left: 2rem

  .product-button
    font-weight: 500
    display: inline-block
    position: relative
    color: #fff
    background-color: $brand-color
    border-radius: 99rem
    max-width: 14rem
    padding: .4rem 2rem 
    font-size: 2rem
    text-align: center
    vertical-align: middle
    border: 1px solid transparent
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out