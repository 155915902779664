// InputSearch.sass
.search-wrap
  display: block
  position: relative

  .search-input-wrapper
    display: block
    position: relative
    // border-radius: 99rem
    background-color: $button-color
    overflow: hidden
    // transition: border-radius 0.1s ease 0s
    @extend %border-rounded-top

    &.not-active
      @extend %border-rounded-bottom

    &.required
      background-color: #fe929f
      transition: color 0.35s, background-color 0.35s

      .search-input
        color: #fcfcfc

      span
        color: #fcfcfc

    .search-input
      display: block
      position: relative
      padding: 1rem 2rem
      font-size: 2rem
      width: 100%
      color: $primary-color
      background-color: transparent
      z-index: 2

    span
      display: block
      font-size: 2rem
      color: rgb(200, 200, 204)
      position: absolute
      top: 50%
      left: 20px
      margin-top: -13px
      z-index: 1
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      // Не понял для чего это нужно
      // width: calc(100% - 60px)
      transition: opacity 0.3s ease 0s, transform 0.3s ease 0s

    // if input focused, hide span (placeholder)
    .search-input:focus ~ span
      opacity: 0
      transform: translateX(10px)

    // if input value not empty, hide span (placeholder)
    &.hide-span 
      span
        opacity: 0
        transform: translateX(10px)

  .search-input-suggestions
    position: absolute
    z-index: 3
    background-color: $button-color
    // background-color: #f3f3f4
    left: 0px
    right: 0px
    visibility: hidden
    opacity: 0
    transition: opacity 0.3s, transform 0.3s ease 0s

    &.active
      visibility: visible
      opacity: 1
      @extend %border-rounded-bottom

    ul
      padding: 0
      margin-top: 0

      li
        display: flex
        flex-direction: column
        padding: 1rem 2rem
        cursor: pointer

        &:hover, &.active
          background-color: #fcfcfc

        &:last-child
          @extend %border-rounded-bottom

        .address-subtitle
          margin-top: 6px
          font-size: 14px
          color: rgba(0, 0, 0, 0.5)

        .highlighted-text
          font-weight: 600