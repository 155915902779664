// Button.sass
.main-button
  font-weight: 600
  display: inline-flex
  position: relative
  color: $primary-color
  background-color: $button-color  
  border-radius: 99rem
  padding: 0.5rem 1.5rem
  font-size: 1.5rem
  text-align: center
  vertical-align: middle
  border: 1px solid transparent
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  opacity: 1
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out

  &:hover
    opacity: .9

  &.button-dropdown-modern
    padding: 1rem 1.5rem
    font-size: 1.8rem

  &.branded
    color: #fff
    background-color: $brand-color