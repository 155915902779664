// InputRadio.sass
.input-radio
  display: flex
  position: relative
  padding-left: 4rem
  margin-bottom: 1rem
  height: 30px
  flex-direction: row
  justify-content: space-between
  align-items: center
  cursor: pointer

  &.required
    transition: color 0.35s, background-color 0.35s, border 0.35s

    .label-name
      color: #fe929f

    .checkmark
      border: 2px solid #fe929f

    input:checked ~ .checkmark:after
      background-color: #fe929f

    input:checked ~ .checkmark
      border: 2px solid #fe929f

  input
    position: absolute
    opacity: 0
    // cursor: pointer
    height: 0
    width: 0

  .label-name
    opacity: 1
    color: $branded-color
    font-size: 1.8rem
    font-weight: 600

  .checkmark
    position: absolute
    top: 0
    left: 0
    height: 30px
    width: 30px
    // background-color: #eee
    background-color: #ffffff
    border: 2px solid $branded-color
    border-radius: 50%
    // -webkit-transition: background-color .35s,border-color .35s
    // transition: background-color .35s,border-color .35s

    &:after
      content: ""
      position: absolute
      // display: none
      top: 12px
      left: 12px
      width: 2px
      height: 2px
      border-radius: 50%
      background: #ffffff
      -webkit-transition: all .35s
      transition: all .35s

  &:hover input ~ .checkmark
    opacity: .8

  input:checked ~ .checkmark:after
    display: block
    top: 2px
    left: 2px
    width: 22px
    height: 22px
    background: $branded-color

  input:checked ~ .checkmark
    border: 2px solid $branded-color

  // input:checked:parent(.form-radio)
  //   cursor: default

// old version
// .form-radio
//   display: flex
//   position: relative
//   padding: 1rem 0
//   flex-direction: row
//   justify-content: space-between
//   align-items: center

//   input
//     position: absolute
//     opacity: 0
//     cursor: pointer
//     height: 0
//     width: 0
    
//     // &.active ~ .checkmark:after
//     //   display: block

//   .label-name
//     opacity: .6

//   .checkmark
//     position: absolute
//     top: 1rem
//     right: 0
//     height: 18px
//     width: 18px
//     // background-color: #eee
//     background-color: #ffffff
//     border: 1px solid #0f0248
//     border-radius: 50%

//     -webkit-transition: background-color .35s,border-color .35s
//     transition: background-color .35s,border-color .35s

//     &:after
//       content: ""
//       position: absolute
//       display: none
//       top: 3px
//       right: 3px
//       width: 10px
//       height: 10px
//       border-radius: 50%
//       background: #0f0248

//   &:hover input ~ .checkmark
//     opacity: .8

//   input:checked ~ .checkmark:after
//     display: block

//   input:checked ~ .checkmark
//     border: 1px solid #0f0248