// AboutUsPage.sass
.about-us-page-container
  display: flex
  position: relative
  flex-direction: column
  width: 100%
  flex-grow: 1
  padding: 6rem 1.6rem 0

  .about-us-page-title
    display: flex
    position: relative
    width: 100%

    h1
      font-size: 3.5rem
      margin: 3.5rem 0

  .about-us-page-body
    display: flex
    position: relative
    flex-direction: column
    width: 100%
    margin-bottom: 3.2rem

    h2
      font-size: 2.4rem
      font-weight: 500
      margin-bottom: 1.6rem

    .image-wide
      border-radius: 22px
      overflow: hidden
      margin-bottom: 1.6rem

      img
        display: block
        width: 100%
        height: auto

    .our-team-block
      display: flex
      flex-direction: row
      flex-wrap: wrap

      .staff-photo
        display: block
        border-radius: 22px
        overflow: hidden
        width: calc( ( 100% - 1.6rem*2 ) / 3 )
        margin-bottom: 1.6rem
        margin-right: 1.6rem

        img
          display: block
          width: 100%
          height: auto

      .staff-photo:nth-child(3n+3)
        margin-right: 0px

@media screen and (min-width: $layout-breakpoint-laptop)
  $padding-laptop: calc((100% - 1024px)/2)

  .about-us-page-container
    padding: 6rem $padding-laptop 0