.cart-total
    display: flex
    position: relative
    flex-direction: column
    // align-items: flex-end
    width: 100%
    margin-bottom: 3rem

    &:last-child
      margin-bottom: 0px

    .cart-total-line
      display: flex
      flex-direction: row
      justify-content: space-between
      margin-bottom: 1rem

      h2
        position: relative
        font-size: 2.2rem
        font-weight: 500

      .cart-total-line-span-value
        // font-size: 18px
        font-size: 2rem
        font-weight: 500
        margin-right: .2rem

      .cart-total-line-span-currency
        font-size: 14px
        font-weight: 600

    // span
    //   position: relative
    //   font-weight: 600
    //   margin-left: 2rem

    //   &:first-child
    //     margin-left: 0px

    //   &.value-discount
    //     // margin: 1rem 0
    //     color: rgb(194, 194, 196)

    //   &.value-discount:after
    //     display: block
    //     content: " "
    //     height: 3px
    //     position: absolute
    //     top: 50%
    //     margin-top: calc(-0.0625em)
    //     left: -3px
    //     right: -3px
    //     background-color: rgb(112, 112, 112)