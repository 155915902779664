// DropdownModern.sass
$scrollbar-border-radius: 4px

.dropdown-modern-wrapper
  .dropdown-modern
    position: absolute
    margin-top: .8rem
    background: #fcfcfc
    border: 2px solid $button-color
    border-radius: $border-radius-dropdown
    opacity: 0
    z-index: 400
    overflow: hidden
    -webkit-transform-origin: 50% 80%
    -ms-transform-origin: 50% 80%
    transform-origin: 50% 80%
    -webkit-transform: scale(.75) translateY(-21px)
    -ms-transform: scale(.75) translateY(-21px)
    transform: scale(.75) translateY(-21px)
    -webkit-transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out
    transition: all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out

    &.toTheLeft
      right: 0

    &.open
      visibility: visible
      opacity: 1
      // animation test like LinguaTrip
      pointer-events: auto
      -webkit-transform: scale(1) translateY(0)
      -ms-transform: scale(1) translateY(0)
      transform: scale(1) translateY(0)

    &.closed
      visibility: hidden

    &.list-large
      max-height: 190px

    .dropdown-modern-list
      position: relative

      &.list-large::-webkit-scrollbar
        width: 8px
        border-top-right-radius: $scrollbar-border-radius
        -moz-border-radius-top-right: $scrollbar-border-radius
        -webkit-border-top-right-radius: $scrollbar-border-radius
        border-bottom-right-radius: $scrollbar-border-radius
        -moz-border-radius-bottom-right: $scrollbar-border-radius
        -webkit-border-bottom-right-radius: $scrollbar-border-radius
        z-index: 399

      &.list-large::-webkit-scrollbar-thumb
        -webkit-border-radius: $scrollbar-border-radius
        border-radius: $scrollbar-border-radius
        background-color: #4c7de0
        z-index: 399

      &.list-large
        max-height: 190px
        overflow-y: scroll

        li
          &:first-child
            border-top-left-radius: $border-radius-dropdown
            -moz-border-radius-topleft: $border-radius-dropdown
            -webkit-border-top-left-radius: $border-radius-dropdown
            border-top-right-radius: 0
            -moz-border-radius-top-right: 0
            -webkit-border-top-right-radius: 0

          &:last-child
            border-bottom-left-radius: $border-radius-dropdown
            -moz-border-radius-bottomleft: $border-radius-dropdown
            -webkit-border-bottom-left-radius: $border-radius-dropdown
            border-bottom-right-radius: 0
            -moz-border-radius-bottom-right: 0
            -webkit-border-bottom-right-radius: 0

      li
        display: flex
        padding: 1rem 3rem
        cursor: pointer

        -webkit-transition: all .2s ease-out
        transition: all .2s ease-out

        &.active
          font-weight: 500
          color: #4c7de0

        &:first-child
          border-top-left-radius: $border-radius-dropdown
          -moz-border-radius-topleft: $border-radius-dropdown
          -webkit-border-top-left-radius: $border-radius-dropdown
          border-top-right-radius: $border-radius-dropdown
          -moz-border-radius-topright: $border-radius-dropdown
          -webkit-border-top-right-radius: $border-radius-dropdown

        &:last-child
          border-bottom-left-radius: $border-radius-dropdown
          -moz-border-radius-bottomleft: $border-radius-dropdown
          -webkit-border-bottom-left-radius: $border-radius-dropdown
          border-bottom-right-radius: $border-radius-dropdown
          -moz-border-radius-bottomright: $border-radius-dropdown
          -webkit-border-bottom-right-radius: $border-radius-dropdown

        &:hover
          background-color: #e7e7e9