// MobileCart.sass
.mobile-cart-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  position: fixed
  right: 0
  top: 0
  height: 100%
  width: 100%
  background-color: $main-background-color
  z-index: 9998
  transform: translateX(+100%)
  transition: 0.3s
  opacity: 0
  // padding-top: 6rem
  visibility: hidden
  overflow-y: scroll

  &.active
    transform: translateX(0)
    opacity: 1
    visibility: visible

  &.empty
    padding-top: 0
    align-items: center
    justify-content: center  

    // .empty-cart-text-block 
    //   max-width: 90%

    //   p
    //     margin-bottom: 1rem
    //     opacity: .8

    // .empty-cart-image 
    //   margin-top: 3rem
    //   margin-bottom: 3rem

  .mobile-cart-container-body
    display: flex
    width: 100%
    flex-direction: column
    align-items: center
    justify-content: flex-start
    padding: 0 2rem
    margin-bottom: 2rem

    .cart-title
      display: flex
      width: 100%
      margin-bottom: 1rem

      h1
        font-size: 2.5rem
        width: 100%
        text-align: center
        margin: 1.75rem 0

    .cart-items-list
      display: flex
      width: 100%
      flex-direction: column
      align-items: center
      margin-bottom: 1rem
      // padding: 0 1.6rem

      .empty-cart-text-block 
        // max-width: 90%

        p
          margin-bottom: 1rem
          opacity: .8

      .empty-cart-image 
        margin-top: 3rem
        margin-bottom: 3rem

      .cart-item
        display: flex
        width: 100%
        margin-bottom: 1rem

        .cart-item-image
          display: flex
          width: 20%
          // flex: 0 0 20%
          justify-content: flex-start
          align-items: flex-start
          // justify-content: flex-start
          // align-items: flex-start

          &.pizza-item
            // padding: 0 .7rem

          img
            display: flex
            width: 100%
            height: auto

        .cart-item-body
          display: flex
          flex-direction: column
          width: calc( 80% - 10px )
          margin-left: 1rem

          .item-body-title
            // font-size: 1.6rem
            font-size: 2rem
            margin-bottom: .5rem

          .cart-item-desc
            color: $subtitle-color
            // font-size: 1.2rem
            font-size: 1.6rem
            margin-bottom: .5rem

          .item-body-manage-product
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between

            .manage-product-price
              font-size: 1.8rem
              display: flex

            .manage-product-count-control
              display: flex

              .count-control-button
                display: flex
                // height: 2rem
                // width: 2rem
                border-radius: 50rem
                padding: .5rem

                img
                  display: flex
                  height: 2rem
                  width: 2rem

                &.button-minus
                  // background-color: #e1faf4
                  // fill: #009471
                  // color: #009471
                  background-color: $brand-color

                &.button-plus
                  // background-color: #e1faf4
                  // fill: #009471
                  // color: #009471
                  background-color: #00B475

                &.button-remove
                  // background-color: #fae1f4
                  // fill: #c21313
                  // color: #c21313
                  background-color: $brand-color

              .count-control-value
                display: flex
                margin: 0 1rem
                font-size: 2rem
                line-height: 1.5
