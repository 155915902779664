// HomeDesktopSEO.sass
.seo-container
  margin-bottom: 3rem

  h2, h3
    width: 100%
    padding: 0 1.6rem
    font-size: 2.2rem
    font-weight: 500
    margin-top: 1rem
    margin-bottom: 1rem
    color: $primary-color
    opacity: .9

  p
    font-size: 1.6rem
    padding: 0 1.6rem
    // margin-bottom: .5rem
    margin-bottom: 1rem
    color: $primary-color
    opacity: .7