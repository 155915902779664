// Input.sass
.main-input-wrapper
  display: block
  position: relative
  border-radius: 99rem
  background-color: $button-color
  overflow: hidden
  // margin-bottom: 0
  // margin-right: 0
  // padding-right: 1rem

  // &:focus
  //   background-color: #f1f1f1

  &.required
    background-color: #fe929f
    transition: color 0.35s, background-color 0.35s

    .main-input
      color: #fcfcfc

    span
      color: #fcfcfc

  .main-input
    display: block
    position: relative
    padding: 1rem 2rem
    font-size: 2rem
    width: 100%
    color: $primary-color
    background-color: transparent
    z-index: 2

  span
    display: block
    font-size: 2rem
    color: rgb(200, 200, 204)
    position: absolute
    top: 50%
    left: 20px
    margin-top: -13px
    z-index: 1
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    // Не понял для чего это нужно
    // width: calc(100% - 60px)
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s

  // if input focused, hide span (placeholder)
  .main-input:focus ~ span
    opacity: 0
    transform: translateX(10px)

  // if input value not empty, hide span (placeholder)
  &.hide-span 
    span
      opacity: 0
      transform: translateX(10px)

// old version
// .main-input-wrapper
//   display: block
//   position: relative
//   // margin-bottom: 0
//   // margin-right: 0
//   padding-right: 1rem

//   .main-input
//     display: block
//     position: relative
//     padding: 1rem 2rem
//     font-size: 2rem
//     border-radius: 99rem
//     width: 100%
//     color: $primary-color
//     background-color: $button-color
//     z-index: 2