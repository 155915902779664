// Navbar.sass
.navbar
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  position: fixed
  top: 0
  right: 0
  left: 0
  min-height: 6rem
  padding-left: 2rem
  padding-right: 2rem
  z-index: 9996

  &.low-z-index
    z-index: 10

  .navbar-button-left, .navbar-button-right
    height: 3.5rem
    width: 3.5rem