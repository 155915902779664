// MobileCartButton.sass
.mobile-cart-button-container
  position: sticky
  bottom: 20px
  right: 0px
  width: 100%
  transition: opacity 150ms ease 0s
  opacity: 0
  visibility: hidden
  z-index: 8

  &.active
    opacity: 1
    visibility: visible

  .cart-button-toggle
    position: absolute
    display: flex
    -webkit-box-pack: center
    justify-content: center
    -webkit-box-align: center
    align-items: center
    bottom: 1.6rem
    right: 1.6rem
    width: 5.6rem
    height: 5.6rem
    border-radius: 50rem
    // background-color: $button-color
    background-color: $brand-color

    .qty-in-cart
      position: absolute
      display: flex
      -webkit-box-pack: center
      justify-content: center
      -webkit-box-align: center
      align-items: center
      // top: 0px
      // left: 3.8rem
      // min-width: 2.1rem
      // height: 2.1rem
      // border-radius: 1.1rem
      top: -7px
      left: 3.25rem
      min-width: 2.5rem
      height: 2.5rem
      border-radius: 1.25rem
      background: #ffffff
      border: 2px solid $brand-color
      color: $primary-color
      font-weight: 600

  .cart-button-icon
    width: 32px
    height: 32px
    display: inline-block
    vertical-align: top
    text-align: center
    font-size: 0px
    line-height: 0
    font-style: normal

    img
      width: 32px
      height: 32px
    // svg
    //   display: inline-block
    //   vertical-align: top
    //   max-width: 100%
    //   max-height: 100%