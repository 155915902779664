// ContactsPage.sass
.contacts-page-container
  display: flex
  position: relative
  flex-direction: column
  width: 100%
  flex-grow: 1
  padding: 6rem 1.6rem 0

  .divider
    margin-bottom: 1.6rem

  .contacts-page-title
    display: flex
    position: relative
    width: 100%
    h1
      font-size: 3.5rem
      margin: 3.5rem 0

  .contacts-page-body
    display: flex
    position: relative
    flex-direction: column
    width: 100%

    h2
      font-size: 2.4rem
      font-weight: 500
      margin-bottom: 1.6rem

    .contacts-page-list-item
      display: flex
      position: relative
      flex-direction: row
      align-items: center
      margin-bottom: 1.6rem

      .icon
        height: 30px
        width: 30px
        margin-right: 1.6rem

      span
        font-size: 2rem

@media screen and (min-width: $layout-breakpoint-laptop)
  $padding-laptop: calc((100% - 1024px)/2)

  .contacts-page-container
    padding: 6rem $padding-laptop 0