// Header.sass
.header
  display: block
  position: fixed
  top: 0px
  width: 100%
  background-color: $header-background-color
  padding: 0 2rem
  z-index: 10

  &.header-with-categories
    padding: 0

  &.header-drop-shadow
    &:before
      content: ""
      position: absolute
      left: 0px
      top: 0px
      width: 100%
      height: 100%
      box-shadow: rgba(50, 5, 5, 0.1) 0px 4px 30px

  .header-container
    display: flex
    flex-direction: row
    align-items: center
    height: 6rem
    z-index: 10

    .header-logo
      z-index: 10
      // height: 3.5rem
      // width: 3.5rem

      img
        // height: 4.5rem
        // width: 4rem
        z-index: 10
        height: 2.5rem

    .header-logo-text
      margin-left: 2rem

      img
        // height: 4.5rem
        height: 3.5rem
        // width: 23.4rem

    .header-desktop
      margin-left: 3rem
      display: flex
      flex: 1 1 100%
      flex-direction: row
      justify-content: space-between
      align-items: center

      .header-desktop-item
        display: flex
        position: relative
        flex-direction: row
        // align-items: baseline
        // padding-left: 2rem
        // margin-bottom: 2rem

        h2
          color: $title-color
          // font-size: 1.8rem
          font-size: 1.6rem
          font-weight: 500
          padding-left: .5rem

          a
            color: $title-color

        .icon
          width: 2rem
          height: 2rem

      .button-select-city
        background-color: #ffffff
        // font-size: 1.8rem
        padding: 0
        display: flex
        position: relative
        flex-direction: row

        h2
          color: $title-color
          // font-size: 1.8rem
          font-size: 1.6rem
          font-weight: 500
          line-height: 1.15
          padding-left: .5rem

        .icon
          width: 2rem
          height: 2rem

      .header-login
        display: flex
        position: relative
        flex-direction: row
        // for fix transition phone, time and city
        // min-width: 24.2rem
        min-width: 25.2rem
        justify-content: flex-end

        .lang-icon
          position: relative
          width: 2rem
          height: 2rem
          margin-right: 1rem

  .carousel-categories-wrapper
    // position: relative
    width: 100%
    height: 6rem

    top: 0
    right: 0
    left: 0
    position: fixed
    // transform: translateY(-100%)
    transition: 0.3s
    opacity: 0
    visibility: hidden
    z-index: 11
    background-color: #fff

    &.active
      // transform: translateY(0)
      opacity: 1
      visibility: visible

    .carousel-categories-container
      padding: 1.4rem 2rem
      // padding-top: 1.4rem
      // padding-bottom: 1.4rem
      // padding-left: 2rem
      // padding-right: 2rem

    ul
      li
        color: $categories-color
        display: inline-block
        vertical-align: middle
        font-size: 1.4rem
        cursor: pointer
        font-weight: 600
        background-color: transparent
        border-radius: 99rem
        overflow: hidden

        &.active
          color: $categories-active-color
          // background-color: $categories-active-background-color
          transition-duration: 200ms
          // box-shadow: 0 16px 64px rgba(25,29,58,.16)
          box-shadow: rgba(50, 5, 5, .16) 0px 4px 30px

        &:last-child
          margin-right: 2rem

        .category-item-name
          padding: .8rem 1.5rem