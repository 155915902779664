// Footer.sass
.footer
  display: flex
  flex-grow: 0
  flex-direction: column
  width: 100%
  background-color: $banner-color
  z-index: 1029

  .first-line, .second-line
    display: flex
    justify-content: space-between
    // align-items: center
    min-height: 6rem
    padding: 1.6rem

  .first-line
    flex-direction: column
    align-items: flex-start

    .footer-menu-wrapper
      display: flex
      flex-direction: column

      .footer-menu
        display: flex
        flex-direction: column
        margin-bottom: 1.5rem

        li
          margin-bottom: .5rem

          a
            font-weight: 500

          &:first-child
            font-weight: 500

          &:last-child
            margin-bottom: 0px

    .footer-social
      display: flex
      justify-content: space-around

      li
        // background-color: $social-icon-circle-color
        background-color: $second-color
        margin-right: 1rem
        border-radius: 99rem

        a
          display: block
          // height: 40px
          // width: 40px
          padding: 10px

          .icon
            color: #fcfcfc
            fill: #fcfcfc
            height: 20px
            width: 20px

        &:last-child
          margin-right: 0px

  .second-line
    background-color: $banner-color
    flex-direction: column
    align-items: center

    p
      margin-bottom: 1rem

      .icon, img
        height: 18px
        width: 18px

    p:last-child
      margin-bottom: 0rem