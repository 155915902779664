// media.sass

// $layout-breakpoint-small: 960px

@media screen and (min-width: $layout-breakpoint-small)
  .carousel-banners-container
    // height: 13.2rem

    .scroll-container
      .banner-item
        width: 16.8rem
        height: 10rem
        margin-right: 1.2rem

  .header 
    .carousel-categories-wrapper 
      .carousel-categories-container
        white-space: nowrap
        overflow-x: scroll
        overflow-y: visible
        scroll-behavior: smooth

  .menu
    // flex-direction: column
    // flex-wrap: wrap

    .menu-section
      flex-direction: column
      flex-wrap: wrap

      .menu-item
        width: 100%

      .menu-item, .menu-item-pizza
        .product-image
          flex: 0 0 45%
        .product-description
          flex: 0 0 55%

  .footer 
    .second 
      flex-direction: column
      min-height: 10rem

      p
        line-height: 1.25

@media screen and (min-width: $layout-breakpoint-tablet)
  // $products-in-line: 3
  // $pizzas-in-line: 4
  $products-in-line: 4

  .banners-container
    .banner-item
      padding: 1rem 2rem
      // padding: 0

  .carousel-banners-container
    // height: 16.8rem

    .scroll-container
      .banner-item
        width: 72rem
        height: 28rem
        margin-right: 3rem

  .header 
    .carousel-categories-wrapper 
      .carousel-categories-container
        display: flex
        justify-content: space-between

  .menu
    // flex-direction: row
    // flex-wrap: wrap

    .menu-section
      flex-direction: row
      flex-wrap: wrap

      // .menu-item
      //   // width: (100%/$products-in-line)
      //   // width: (calc(100%-3.2rem*3)/$products-in-line)
      //   width: calc((100% - 3.2rem*3)/4)

      .menu-item-pizza, .menu-item
        flex-direction: column !important
        justify-content: space-between
        // width: ((100%)/$products-in-line)
        // width: calc((100%-3.2rem*3)/$products-in-line)
        // width: calc((100% - 3.2rem*3)/4)
        width: calc( 100% / 4 )

      .menu-item-pizza, .menu-item
        .product-image
          flex: none
        .product-description
          flex: none
          flex-grow: 1

  .menu
    .menu-section
      .menu-item-pizza 
        .product-description 
          .product-contains
            font-size: 1.4rem

  .footer
    .first-line
      flex-direction: row
      padding: 1.6rem 0

      .footer-menu-wrapper
        flex-direction: row

        .footer-menu
          margin-right: 2rem

        .footer-menu:last-child
          margin-right: 0px

    .second-line
      flex-direction: row
      padding: 1.6rem 0

  .cookie-policy-container
    flex-flow: row nowrap
    padding: 1rem 2rem
    // padding: 1rem calc((100% - 1200px)/2)

    p
      text-align: left

  .static-page-container
    .static-page-title
      width: 100%

      h1
        font-size: 3.5rem
        margin: 3.5rem 0

    .static-page-body
      p, ul, li
        font-size: 2rem
        width: 680px

@media screen and (min-width: $layout-breakpoint-laptop)
  $padding-laptop: calc((100% - 1024px)/2)

  .header, .header-compact, .order-container .order-title, .order-container .order-body-wrapper, .map-component-container, .menu
    padding: 0 $padding-laptop

  .header
    .carousel-categories-wrapper
      .carousel-categories-container
        padding: 1.4rem $padding-laptop

  .cart-container
    padding: 6rem $padding-laptop 0

  .menu
    padding: 0 calc((100% - 1024px)/2)

    .menu-category-title
      padding: 1.6rem 0

    .menu-section
      .menu-item-pizza, .menu-item
        width: calc((100% - 3.2rem*3)/4)
        padding: 0
        margin-right: 3.2rem
        margin-bottom: 3.2rem

        .product-description
          padding-left: 0

      .menu-item-pizza:nth-child(4n+4), .menu-item:nth-child(4n+4)
        margin-right: 0px

  .footer
    padding: 0 calc((100% - 1024px)/2)

  .seo-container
    padding: 0 calc((100% - 1024px)/2)

    h2, h3, p
      padding: 0

  .map-component-container
    h2
      padding: 1.6rem 0

  .cookie-policy-container
    padding: 1rem calc((100% - 1024px)/2)

@media screen and (min-width: $layout-breakpoint-mdpi)
  $padding-mdpi: calc((100% - 1024px)/2)

  .header, .order-container .order-title, .order-container .order-body-wrapper, .map-component-container
    padding: 0 $padding-mdpi

  .header
    .carousel-categories-wrapper
      .carousel-categories-container
        padding: 1.4rem $padding-mdpi

  .cart-container
    padding: 6rem $padding-mdpi 0

  .banners-container
    &:before
      position: absolute
      content: ""
      top: 0
      bottom: 0
      left: 0
      // width: 108px
      // width: 128px
      width: calc((100% - 1024px)/2)
      background: linear-gradient(90deg,rgba(255,255,255,.5),hsla(0,0%,100%,0))
      z-index: 1

    &:after
      position: absolute
      content: ""
      top: 0
      bottom: 0
      right: 0
      // width: 108px
      // width: 128px
      width: calc((100% - 1024px)/2)
      // background: linear-gradient(0deg,#fff,hsla(0,0%,100%,0))
      background: linear-gradient(-90deg,rgba(255,255,255,.5),hsla(0,0%,100%,0))
      z-index: 1

  .footer
    padding: 0 calc((100% - 1024px)/2)

  .seo-container
    padding: 0 calc((100% - 1024px)/2)

    h2, h3, p
      padding: 0
