// CookiePolicyContainer.sass
.cookie-policy-container
  position: fixed
  bottom: -1px
  left: 0
  z-index: 999
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: space-between
  width: 100%
  // max-width: 1336px
  // color: #fff
  // background: #f1f1f1
  // background-color: #f6f7f7
  // background-color: #fcfcfc
  background-color: #e7e7e9
  padding: 1.6rem

  p
    margin-bottom: 1rem
    text-align: center

  .cookie-policy-container__last
    padding: .6rem

  &.hide
    display: none