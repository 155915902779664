// HeaderCompact.sass
.header-compact
  display: block
  position: fixed
  top: 0px
  width: 100%
  background-color: $header-background-color
  padding: 0 2rem
  z-index: 10

  .header-compact-container
    display: flex
    flex-direction: row
    align-items: center
    height: 6rem
    z-index: 10

    .header-compact-logo
      z-index: 10

      img
        z-index: 10
        height: 2.5rem

    .header-compact-content
      // margin-left: 3rem
      margin-left: 1.6rem
      display: flex
      flex: 1 1 100%
      flex-direction: row
      justify-content: space-between
      align-items: center

      .header-compact-select-lang
        display: flex
        position: relative
        flex-direction: row
        width: 100%
        justify-content: flex-end

        .lang-icon
          position: relative
          width: 2rem
          height: 2rem
          margin-right: 1rem

        .select-lang-dropdown-body
          right: 0

      .header-compact-content-item
        display: flex
        position: relative
        flex-direction: row

        h2
          color: $title-color
          font-size: 1.8rem
          font-weight: 500
          padding-left: .5rem

          a
            color: $title-color

        .icon
          width: 2rem
          height: 2rem