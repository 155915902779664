// TextArea.sass
.main-textarea-wrapper
  display: block
  position: relative
  border-radius: 24px
  background-color: $button-color
  width: 100%
  overflow: hidden
  // margin-bottom: 0
  // margin-right: 0
  // padding-right: 1rem

  .main-textarea
    display: block
    position: relative
    padding: 1rem 2rem
    font-size: 2rem
    width: 100%
    color: $primary-color
    background-color: transparent
    z-index: 2
    resize: none
    overflow: auto

  span
    display: block
    font-size: 2rem
    color: rgb(200, 200, 204)
    position: absolute
    // top: 50%
    top: 2.5rem
    left: 20px
    margin-top: -13px
    z-index: 1
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    // Не понял для чего это нужно
    // width: calc(100% - 60px)
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s

  // if input focused, hide span (placeholder)
  .main-textarea:focus ~ span
    opacity: 0
    transform: translateX(10px)

  // if input value not empty, hide span (placeholder)
  &.hide-span 
    span
      opacity: 0
      transform: translateX(10px)

@media screen and (min-width: 520px)
  .main-textarea-wrapper
    width: 37rem
    min-width: 37rem
