%mobile-menu-line-icon
  position: absolute
  display: block
  // left: 3.5rem
  // top: 1.5rem
  width: 30px
  height: 30px

.mobile-menu-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  position: fixed
  right: 0
  top: 0
  height: 100vh
  width: 100%
  // background-color: #f1f1f1
  background-color: $main-background-color
  transform: translateX(+100%)
  transition: 0.3s
  opacity: 0
  padding-top: 6rem
  // padding-left: 1.6rem
  // padding-right: 1.6rem
  visibility: hidden
  // z-index: 100
  z-index: 9

  &.active
    transform: translateX(0)
    opacity: 1
    visibility: visible

  .mobile-menu-bottom
    display: flex
    position: relative
    flex-direction: row
    // align-items: center
    // justify-content: center
    width: 100%
    padding-left: 4rem
    // padding-top: 2rem

    .lang-icon
      position: relative
      width: 2.5rem
      height: 2.5rem
      margin-right: 1rem

  .mobile-menu-lines
    display: flex
    position: relative
    flex-direction: column
    width: 100%
    padding-left: 4rem
    padding-top: 1rem

    .mobile-menu-line
      display: flex
      position: relative
      width: 100%
      height: 30px
      flex-direction: row
      align-items: center
      margin-bottom: 2.4rem

      &:last-child
        margin-bottom: 0px

      &.mobile-menu-line-city:before
        content: url('../assets/icons/navigation/geo.svg')
        @extend %mobile-menu-line-icon

      &.mobile-menu-line-clock:before
        content: url('../assets/icons/navigation/clock.svg')
        @extend %mobile-menu-line-icon

      &.mobile-menu-line-phone:before
        content: url('../assets/icons/navigation/phone.svg')
        @extend %mobile-menu-line-icon

      &.mobile-menu-line-ru:before
        content: url('../assets/icons/flags/ru.svg')
        @extend %mobile-menu-line-icon

      &.mobile-menu-line-en:before
        content: url('../assets/icons/flags/en.svg')
        @extend %mobile-menu-line-icon

      h2
        color: $title-color
        font-size: 2.2rem
        font-weight: 500
        padding-left: 4rem
        // padding: 2rem 0 2rem 4rem

        a
          color: $title-color
          font-weight: 500

  .mobile-menu-list
    display: flex
    position: relative
    width: 100%
    // padding-left: 8rem
    padding-left: 4rem
    font-size: 2.2rem
    margin: 2.4rem 0

    li
      color: $title-color
      font-weight: 500
      padding: 1rem 0

  .mobile-menu-line-title
    width: 100%
    // font-size: 2.2rem
    // padding-left: 6rem
    // margin-bottom: 2rem
    font-size: 2.2rem
    // padding: 2rem
    padding: 2rem 2rem 2rem 4rem

  .mobile-menu-cities-list
    display: flex
    position: relative
    width: 100%
    // padding-left: 6rem
    font-size: 2rem

    ul
      width: 100%
      position: relative

      li
        position: relative
        width: 100%
        color: $title-color
        padding-top: 1.4rem
        // padding-left: 4rem
        padding-left: 6rem
        // padding-right: 2rem
        padding-bottom: 1.4rem
        // margin-bottom: 1rem
        // border-radius: 12px

        &.active
          // color: $primary-color
          // background-color: lightgrey
          color: #fff
          background-color: $brand-color

          &:after
            content: ""
            position: absolute
            display: block
            right: 2.5rem
            // top: 1rem
            // width: 10px
            // height: 15px
            top: 1.2rem
            width: 14px
            height: 21px
            // border: solid grey
            border: solid #fff
            border-width: 0 4px 4px 0
            -webkit-transform: rotate(45deg)
            -ms-transform: rotate(45deg)
            transform: rotate(45deg)