// SelectCityPage.sass
.select-city-page-container
  display: flex
  position: relative
  flex-direction: column
  align-items: center
  flex-grow: 1
  min-height: 100%
  width: 100%
  padding-top: 6rem

  .select-city-page-title
    position: relative
    width: 70%

    h1
      text-align: center
      font-size: 2.4rem
      margin: 2.1rem 0

  .select-city-page-body
    display: flex
    position: relative
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%

    ul
      padding: 0 2.4rem
      margin-bottom: 1rem
      width: 100%
      li
        position: relative
        color: rgb(41, 41, 41)
        font-size: 1.8rem
        font-weight: 500
        padding: 1rem 0
        .select-city-button
          padding: 1rem 2.5rem
          font-size: 1.8rem

@media screen and (min-width: $layout-breakpoint-tablet)
  .select-city-page-container
    .select-city-page-title
      width: 100%

      h1
        font-size: 3.5rem
        margin: 3.5rem 0

    .select-city-page-body
      ul
        width: 680px
        max-width: 680px
        li
          font-size: 2rem