// FormCheckbox.sass
// new version
.input-checkbox
  display: flex
  position: relative
  // padding-left: 4rem
  margin-bottom: 1rem
  min-height: 30px
  flex-direction: row
  // justify-content: space-between
  align-items: center
  cursor: pointer

  &.required
    transition: color 0.35s, background-color 0.35s, border 0.35s

    .label-name
      color: #fe929f

    .checkmark
      border: 2px solid #fe929f

    // input:checked ~ .checkmark:after
    //   background-color: #fe929f

    input:checked ~ .checkmark
      background-color: #fe929f

    // input:checked ~ .checkmark
    //   border: 2px solid #fe929f

  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

  .label-name
    opacity: 1
    color: $branded-color
    padding-left: 1rem
    font-size: 1.8rem
    font-weight: 600

  .checkmark
    display: inline-block
    position: relative
    // top: 0
    // left: 0
    height: 30px
    width: 30px
    min-height: 30px
    min-width: 30px
    background-color: #ffffff
    border: 2px solid $branded-color
    border-radius: 10px
    -webkit-transition: background-color .35s,border-color .35s
    transition: background-color .35s,border-color .35s

    &:after
      content: ""
      position: absolute
      display: none
      left: 10px
      top: 5px
      width: 6px
      height: 12px
      border: solid white
      border-width: 0 3px 3px 0
      // left: 6px
      // top: 3px
      // width: 5px
      // height: 10px
      // border: solid white
      // border-width: 0 3px 3px 0
      -webkit-transform: rotate(45deg)
      -ms-transform: rotate(45deg)
      transform: rotate(45deg)

  &:hover input ~ .checkmark
    opacity: .8

  input:checked ~ .checkmark:after
    display: block

  input:checked ~ .checkmark
    background-color: $branded-color

  input:checked ~ .form-checkbox
    cursor: default

// old version
// .form-checkbox
//   display: flex
//   position: relative
//   // padding: 1rem 0
//   // padding: 1rem 0 1rem 2.5rem
//   padding-left: 2.5rem
//   margin-bottom: 1rem
//   flex-direction: row
//   justify-content: space-between
//   align-items: center

//   input
//     position: absolute
//     opacity: 0
//     cursor: pointer
//     height: 0
//     width: 0

//   .label-name
//     opacity: .8

//   .checkmark
//     position: absolute
//     // top: 1rem
//     // right: 0
//     top: 0
//     left: 0
//     height: 18px
//     width: 18px
//     // background-color: #eee
//     background-color: #ffffff
//     border: 1px solid #0f0248
//     border-radius: 5px
//     // #91c044

//     -webkit-transition: background-color .35s,border-color .35s
//     transition: background-color .35s,border-color .35s

//     &:after
//       content: ""
//       position: absolute
//       display: none
//       // top: 3px
//       // right: 3px
//       // width: 10px
//       // height: 10px
//       // border-radius: 50%
//       // background: #0f0248
//       left: 6px
//       top: 3px
//       width: 5px
//       height: 10px
//       border: solid white
//       border-width: 0 3px 3px 0
//       -webkit-transform: rotate(45deg)
//       -ms-transform: rotate(45deg)
//       transform: rotate(45deg)

//   &:hover input ~ .checkmark
//     opacity: .8

//   input:checked ~ .checkmark:after
//     display: block

//   input:checked ~ .checkmark
//     // background-color: #91c044
//     // border: 1px solid #91c044
//     background-color: #0f0248