// StaticPage.sass
.static-page-container
  display: flex
  position: relative
  flex-direction: column
  align-items: center
  min-height: 100%
  width: 100%
  padding-top: 6rem

  .static-page-title
    position: relative
    width: 70%

    h1
      text-align: center
      font-size: 2.1rem
      margin: 2.1rem 0

  .static-page-body
    display: flex
    position: relative
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%

    .static-page-body-image
      margin-top: 3rem
      margin-bottom: 3rem
      // width: 50%

    p, ul, li
      position: relative
      color: rgb(41, 41, 41)
      font-size: 1.6rem
      width: 100%
      line-height: 1.5

    p, ul
      padding: 0 2.4rem

    ul
      li
        padding-left: calc(15px + 0.25em)

      li:before
        content: ''
        display: block
        height: 12px
        width: 12px
        border-radius: 50%
        background: rgb(100, 100, 100)
        border: 2px solid rgb(100, 100, 100)
        position: absolute
        left: 0
        top: calc(1.6em/4) !important

    p, ul
      margin-bottom: 1rem