// Products.sass
.menu
  display: flex
  flex-direction: column
  width: 100%
  background-color: $main-background-color

  .menu-category-title
    font-size: 5rem
    // font-weight: 900
    font-weight: 700
    color: #e8e8e8
    line-height: 1
    // text-align: center
    // font-size: 3rem
    width: 100%
    padding: 1.6rem

  .menu-section
    display: flex

    .menu-item, .menu-item-pizza
      display: flex
      flex-direction: row
      // width: 100%
      // min-height: 21rem
      padding: 1.6rem

      .product-image
        display: flex
        // flex: 0 0 45%
        justify-content: flex-start
        align-items: flex-start

        img
          display: flex
          width: 100%
          height: auto

      .product-description
        display: flex
        // flex: 0 0 55%
        flex-direction: column
        padding-left: 1.5rem
        padding-top: .8rem

        .product-title
          // display: flex
          // align-items: center
          color: $title-color
          font-size: 1.6rem
          font-weight: 700
          margin-bottom: .8rem

          .product-calzone
            color: #777
            font-weight: 600
            // font-size: 1.6rem
            // margin-left: .8rem

          .product-tag-vegan, .product-tag-spicy
            margin-left: .4rem

        .product-price

        .product-contains
          color: $title-color
          font-size: 1.2rem
          margin-bottom: 1.6rem
          line-height: 1.25

        .product-sizes
          display: flex
          margin-bottom: 1.6rem

          ul
            display: flex
            justify-content: space-around
            list-style: none

            li
              font-weight: 500
              color: $li-not-active-color
              font-size: 1.3rem
              border-radius: 99rem
              padding: .75rem 1.25rem
              margin-right: .5rem
              cursor: pointer

              &:last-child
                margin-right: 0px

              &.active
                color: $primary-color
                // background-color: $button-color
                transition-duration: 200ms
                box-shadow: rgba(50, 5, 5, .15) 0px 4px 30px
                cursor: default

      .product-management
        display: flex
        justify-content: space-between
        align-items: center

        .product-price
          font-size: 1.8rem
          font-weight: 600
          color: #333

          span
            font-size: 1.2rem
            margin-left: .5rem

        .product-management-controls
          display: flex
          align-items: center

          .count-control-button
            display: flex
            border-radius: 50rem
            padding: .5rem

            img
              display: flex
              width: 2.2rem
              height: 2.2rem

            &.button-minus
              // branded icons
              background-color: $brand-color

            &.button-plus
              // branded icons
              background-color: #00B475

            &.button-remove
              // branded icons
              background-color: $brand-color

          .count-control-value
            display: flex
            margin: 0 1.5rem
            font-size: 2.2rem

        .product-button
          // font-weight: 600
          // display: inline-block
          // position: relative
          // color: $primary-color
          // background-color: $button-color  
          // border-radius: 12px
          // max-width: 11rem
          // // min-height: 3.6rem
          // padding: 0.2rem 1rem 
          // font-size: 1.5rem
          // text-align: center
          // vertical-align: middle
          // border: 1px solid transparent
          // -webkit-user-select: none
          // -moz-user-select: none
          // -ms-user-select: none
          // user-select: none
          // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out

        .product-link
          // font-weight: 600
          // display: inline-block
          // position: relative
          // color: $primary-color
          // background-color: $button-color  
          // border-radius: 12px
          // max-width: 11rem
          // // min-height: 3.6rem
          // padding: 0.3rem 1rem
          // font-size: 1.5rem
          // text-align: center
          // vertical-align: middle
          // border: 1px solid transparent
          // -webkit-user-select: none
          // -moz-user-select: none
          // -ms-user-select: none
          // user-select: none
          // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out