// ImgSlider.sass
.container
  display: block
  position: relative
  margin-top: 60px
  // min-height: calc( 100vh - 250px )
  padding: 0 calc((100% - (1024px + 40px))/2)
  // overflow-x: hidden
  overflow: hidden

  &:hover
    .slick-slider .slider-prev-arrow, .slick-slider .slider-next-arrow
      visibility: visible

  &:before
    position: absolute
    content: ""
    top: 0
    bottom: 0
    left: 0
    width: calc((100% - 1024px)/2)
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))
    z-index: 1

  &:after
    position: absolute
    content: ""
    top: 0
    bottom: 0
    right: 0
    width: calc((100% - 1024px)/2)
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))
    z-index: 1

  .wrap-slide
    cursor: pointer
    position: relative
    padding: 0 2rem
    // border-radius: 22px
    // overflow: hidden

    // &:hover
    //   opacity: .7

    img
      border-radius: 22px
      width: 100%
      height: 100%

  .slick-slider
    // margin-top: 20px
    margin-top: 10px

    & > button
      opacity: 0
      height: 100%,
      width: 5vw
      z-index: 1

      &:hover
        opacity: 1
        transition: opacity .2s ease 0s

    ul
      li
        button
          &:before
            font-size: 16px
            // color: rgb(150, 158, 171)
            color: rgb(84, 76, 72)

        &.slick-active
          button:before
            color: #fcfcfc
            // color: rgb(197, 202, 206)

    .slick-list
      overflow: initial

    .slider-prev-arrow, .slider-next-arrow
      display: flex
      position: absolute
      align-items: center
      justify-content: center
      // top: calc( 50% - 28px )
      // width: 56px
      // height: 56px
      top: calc( 50% - 25px )
      width: 50px
      height: 50px
      background-color: #fff
      border-radius: 99rem
      // opacity: .75
      z-index: 1
      cursor: pointer
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08)
      visibility: hidden

      .slider-arrow-icon
        width: 25px
        height: 25px

    .slider-prev-arrow
      left: 35px

    .slider-next-arrow
      right: 35px

    // .slick-prev
    //   left: -75px

    // .slick-next
    //   right: -75px

.slick-dots
  bottom: 16px !important

@media screen and (max-width: $layout-breakpoint-tablet)
  .container:hover .slick-slider .slider-prev-arrow, .container:hover .slick-slider .slider-next-arrow
    display: none