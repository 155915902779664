// main.sass

$border-radius-dropdown: 12px
$border-radius-search-input: 22px
$border-radius-dropdown: 12px

%border-round
  border-radius: 12px
  -webkit-border-radius: 12px

// %border-rounded-top
//   border-top-left-radius: $border-radius-search-input
//   -moz-border-radius-topleft: $border-radius-search-input
//   -webkit-border-top-left-radius: $border-radius-search-input
//   border-top-right-radius: $border-radius-search-input
//   -moz-border-radius-topright: $border-radius-search-input
//   -webkit-border-top-right-radius: $border-radius-search-input

// %border-rounded-bottom
//   border-bottom-left-radius: $border-radius-search-input
//   -moz-border-radius-bottomleft: $border-radius-search-input
//   -webkit-border-bottom-left-radius: $border-radius-search-input
//   border-bottom-right-radius: $border-radius-search-input
//   -moz-border-radius-bottomright: $border-radius-search-input
//   -webkit-border-bottom-right-radius: $border-radius-search-input

@import "common"
@import "Header"
@import "HeaderCompact"
@import "Navbar"
@import "ImgSlider"
// @import "GalleryBanners"
@import "Products"
@import "Cart"
@import "OrderBody"
@import "StaticPage"
@import "SelectCityPage"
@import "CookiePolicyContainer"
@import "DeliveryZone"
@import "Button"
@import "DropdownModern"
@import "Input"
@import "InputRadio"
@import "InputCheckbox"
@import "InputSearch"
@import "TextArea"
@import "Loading"

// Mobile - start
@import "MobileCart"
@import "MobileProduct"
@import "MobileCartButton"
@import "MobileMenu"
// Mobile - end

@import "CartTotal.sass"

@import "ContactsPage"
@import "AboutUsPage"
@import "HomeDesktopSEO"
@import "Footer"

// get media
@import "media"